import { memo, useEffect, useRef } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import {
  useStorageState,
  useTheme,
} from "@findep/microfronts-core"

import FindepLayout from './findep'
import { navigateNative, navigateDelete } from '../helpers/queryNavigate'
import { FINDEP_HOME_URL, FINDEP_LOGIN_URL } from '../helpers/constants'
import { AuthService } from '../services/auth/auth.service'
import LogoutButton from '../components/auth/LogoutButton'
import getAuthClientId from '../helpers/getAuthClientId'

const authService = new AuthService();



function LoginLayout({ location, ...props }) {
  const theme = useTheme()

  const logoutCss = css`
    margin-right: .5em;
    font-size: 1.5em;
    ${theme.breakpoints.up('sm')} {
      margin-right: 2em;
    }
  `


  const emptyUser = {
    email: "",
    familyName: "",
    givenName: "",
    googleId: "",
    imageUrl: "",
    fullName: "",
    roles: [],
    isActive: false,
    isAuthenticated: false,
    isCompanyEmail: false,
    isCollaborator: false,
  };
  const emptyStorage = {};

  const [user, setUser] = useStorageState("findep:user", emptyUser);
  const [storage, setStorage] = useStorageState("findep:storage", emptyStorage);

  const appContext = {
    company: "FINDEP",
    project: "FINDEP_COLABORADORES",
    user,
    storage,
    setUser,
    setStorage,
  };

  const prevUser = useRef({ ...user })

  const isLogin = location.pathname === FINDEP_LOGIN_URL

  useEffect(() => {

    const logout = async () => {
      try {
        await authService.logout();
        setUser(emptyUser)
      } catch (e) {
        console.error(`Error: `, e);
      } finally {
        sessionStorage.clear()
        navigateDelete(FINDEP_LOGIN_URL, ['operatorEmail', 'creditApplicationId'], false);
      }
    }

    let urlParams = new URLSearchParams(window.location.search)
    let ct_destination = ''
    if (urlParams.get('destination_path')) {
      ct_destination = `&destination_path=${urlParams.get('destination_path')}`
    }

    const homeUrl = `${FINDEP_HOME_URL}?operatorEmail=${encodeURI(user.email)}${ct_destination}`

    if (JSON.stringify(user) !== JSON.stringify(prevUser.current)) { // se logueo con otro usuario y se deslogueo
      prevUser.current = { ...user }
      if (isLogin) {
        if (user.isCompanyEmail && user.isAuthenticated) {
          navigateNative(homeUrl)
        } else {
          logout()
        }
      } else { // estoy en cualquier pagina que no sea login
        if (!user.isCompanyEmail && !user.isAuthenticated) {
          console.log('logout con cambio de usuario')
          logout()
        }
      }
    }

  }, [user])

  // useEffect(() => {

  //   if (location.pathname === '/') {
  //     if (user.isCompanyEmail &&  user.isAuthenticated) {
  //       navigate(props.pageContext.next.path)
  //     } 
  //   } else {
  //     if (!user.isCompanyEmail && !user.isAuthenticated ) {
  //       navigate('/')
  //     } 
  //   }
  // }, [])

  const gcid = getAuthClientId(props.pageContext.gcid)

  return (
    <FindepLayout appContext={appContext} {...props} menu={!isLogin} menuElement={!isLogin && <LogoutButton css={logoutCss} authClientId={gcid} />} />
  )
}

LoginLayout.propTypes = {
  companyName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  currentStep: PropTypes.number,
  gradient: PropTypes.bool,
  menu: PropTypes.bool,
}

export default memo(LoginLayout)

