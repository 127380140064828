import React from 'react';
import Findep from './findep'
import Login from './LoginLayout'
import HeaderLess from './headerless'

const LayoutsComponent = ({ broker, companyName, location, layout, pageContext, render, gradient, menu, currentStep, iconos }) => {
    switch (layout) {
        case 'login':
            return <Login broker={broker} location={location} pageContext={pageContext} currentStep={(currentStep >= 0 && currentStep !== null) ? currentStep : null} iconos={iconos ? iconos : null} companyName={companyName} gradient={gradient ? true : false} menu={menu ? true : false}>{render()}</Login>
            break;
        case 'headerless':
            return <HeaderLess broker={broker} location={location} pageContext={pageContext} currentStep={(currentStep >= 0 && currentStep !== null) ? currentStep : null} iconos={iconos ? iconos : null} companyName={companyName} gradient={gradient ? true : false} menu={menu ? true : false}>{render()}</HeaderLess>
            break;
        // case 'findep':
        //     console.log("findep");
        //     return <Findep currentStep={(currentStep >= 0 && currentStep !== null) ? currentStep : null} iconos={iconos ? iconos : null} companyName={companyName} gradient={gradient ? true : false} menu={menu ? true : false}>{render()}</Findep>
        //     break;
        default:
            return <Findep broker={broker} location={location} pageContext={pageContext} currentStep={(currentStep >= 0 && currentStep !== null) ? currentStep : null} iconos={iconos ? iconos : null} companyName={companyName} gradient={gradient ? true : false} menu={menu ? true : false}>{render()}</Findep>
            break;
    }
}
export default LayoutsComponent