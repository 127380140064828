import Service from './Service';
import { AuthService as authService } from './config';

export class AuthService extends Service {
    constructor() {
        super();
        this._email = "";
        this._user = "";
        this._password = "";
    }

    /**
     * @param  {String} email
     */
    setEmail(email) {
        this._email = `${email}`;
    }

    /**
     * @param  {String} password
     */
    setPassword(password) {
      this._password = `${password}`;
    }

    /**
     * @param  {String} user
     */
    setUser(user) {
      this._user = `${user}`;
    }

    /**
     * @returns {Promise<import('./SsoService').ServiceResponse|import('./SsoService').ServiceError>}
     */
    login() {
        const self = this;
        return this._requestWrapper(
            authService()({
                method: "POST",
                url: `/user`,
                data: {
                    email: `${self._email}`
                }
            })
        );
    }

  /**
   * @returns {Promise<import('./SsoService').ServiceResponse|import('./SsoService').ServiceError>}
   */
  loginByUser() {
    const self = this;
    return this._requestWrapper(
      authService()({
        method: "POST",
        url: `/by-user`,
        data: {
          user: `${self._user}`,
          password: `${self._password}`,
        }
      })
    );
  }

    /**
     * @returns {Promise<import('./SsoService').ServiceResponse|import('./SsoService').ServiceError>}
     */
    logout() {
        return this._requestWrapper(
            authService()({
                method: "DELETE",
                url: `/user`,
            })
        );
    }
}